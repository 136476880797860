<template lang="pug">
q-layout#app1(view="hHh lpR fFf")
    q-header.bg-indigo-5.text-white(elevated)
        q-toolbar
            // q-btn(v-if="$store.state.auth" flat @click="showLeft=!showLeft"): q-icon(name="menu")
            q-toolbar-title
                | Pathfinder
                span(slot="subtitle") Advanced Rail Systems
            template(v-if="activeYard")
                q-btn(icon="keyboard_arrow_left" flat round @click="$router.replace({name:'dashboard'})")
                q-toolbar-title
                    | {{activeYard.yard}}
                    span(slot="subtitle") {{activeYard.kind}}
            template(v-if="auth")
                div {{auth.name}}
                q-btn(flat icon="exit_to_app" round @click="logout")

    q-page-container

        router-view
        //- isso forca recriacao de todas as views (ao inves do reuso)
        //- router-view(v-bind:key="$route.path")


</template>


<style lang="stylus">

@import '@quasar/extras/material-icons/material-icons.css'
// @import '@quasar/extras/mdi-v5/mdi-v5.css'
@import './style.styl'

body, html
    margin 0
    padding 0
    height 100%

body
    font: caption   // force to use system font ????

</style>


<script lang="coffee">

# import iconSet from 'quasar/icon-set/mdi-v5.js'   # must be used to replace the standard material-icons


module.exports =
    name: 'app'

    # data: ->
    #    showLeft: yes

    computed:
        auth: -> @$store.state.auth
        activeYard: -> @$store.state.activeYard

    # components:
    created: ->
        @$http.get 'auth/'
        .then (res) =>
            console.log res
            @$store.commit 'SET_AUTH', res.data
            @$router.replace {name: 'dashboard'}
        .catch (err) =>
            console.error err
            # @$q.notify {type:'negative', position:'top', message:@$i18n.t('welcome.badLogin')}

    mounted: ->
        # @$q.dark.set yes
        # @$q.iconSet.set iconSet

    # beforeDestroy: ->
    methods:
        logout: ->
            @$http.delete 'auth'
            @$store.commit 'SET_YARD', null
            @$store.commit 'SET_AUTH', null

</script>

<template lang="pug">
.niceInputComp
    span.label {{label}}
    input(
        ref="inp"
        v-model.number="internal"
        @input="changed"
        @wheel="wheel"
        @focus="$refs.inp.select()"
        )
</template>


<style lang="stylus">
.niceInputComp
    input
        margin-left 5px
        width 40px

    // .label
        // font-weight bold

</style>


<script lang="coffee">
module.exports =
    name: 'niceInput'

    # props: ['value', 'label']
    props: {value: [Number, String], label: [Number, String], gain: {type: [Number, String], default: 1}}

    data: ->
        internal: 0

    watch:
        value: (val) -> @internal = val

    created: -> @internal = @value

    methods:
        changed: ->
            @$emit 'input', @internal

        wheel: (e) ->
            delta = 1
            delta = 20 if e.ctrlKey
            delta = 100 if e.ctrlKey and e.shiftKey
            delta *= @gain
            @internal+= delta if e.deltaY < 0
            @internal-= delta if e.deltaY > 0
            @internal+= 20 if e.deltaX > 0
            @internal-= 20 if e.deltaX < 0
            @$emit 'input', @internal
            e.preventDefault()

</script>


.w100
    width 100%

.h100
    height 100%

.iblock
    display inline-block

.iflex
    display inline-flex

.lh1
    line-height 1

.relative
    position relative

.absolute
    position absolute

/* min-widths */

for $w in 1..12
    .minw{$w * 50}
        min-width $w * 50px

/* margins */

$num_margins = 5
$margin_step = 8px
.m0
    margin 0
for $x in 1..$num_margins
    .m{$x}
        margin $margin_step * $x
    .mv{$x}
        margin $margin_step * $x 0
    .mh{$x}
        margin 0 $margin_step * $x

for $direction in (top t) (bottom b) (left l) (right r)
    for $x in 0..$num_margins
        .m{$direction[1]}{$x}
            margin-{$direction[0]} $margin_step * $x

/* paddings */

$num_paddings = 5
$padding_step = 8px
.p0
    padding 0
for $x in 1..$num_paddings
    .p{$x}
        padding $padding_step * $x
    .pv{$x}
        padding $padding_step * $x 0
    .ph{$x}
        padding 0 $padding_step * $x

for $direction in (top t) (bottom b) (left l) (right r)
    for $x in 0..$num_paddings
        .p{$direction[1]}{$x}
            padding-{$direction[0]} $padding_step * $x

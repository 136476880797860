<template lang="pug">
q-page#configpage(padding)

    q-card
        q-card-actions.items-center
            .col {{$t('config.title', {params: $route.params})}}
            .col-auto
                q-btn(color="red" @click="save") Save
        q-card-section.relative-position.full-height
            #cfgEditor(v-shortkey.once="['ctrl', 's']" @shortkey="save")

</template>


<style lang="stylus">
#configpage
    #cfgEditor
        position absolute
        top 0
        bottom 0
        left 0
        right 0
        min-height 80vh
        // min-height 80px
</style>


<script lang="coffee">
module.exports =
    name: 'config'

    data: ->
        yardCfg: null
        editor: null
        saved: no
        dismissError: null

    created: ->
        group = @$route.params.group
        yard = @$route.params.yard

        @$store.commit 'SET_YARD', {yard: group+'/'+yard, kind: @$i18n.t('config.subtitle')}

        @$http.get "yards/configuration/#{group}/#{yard}", {params:{base: '1'}}
        .then (res) =>
            @yardCfg = res.data
            @editor.setValue @yardCfg, -1 if @editor
        .catch (err) =>
            console.error err

    mounted: ->
        @editor = ace.edit 'cfgEditor'
        # @editor.setTheme "ace/theme/iplastic"
        @editor.setTheme "ace/theme/chaos"
        @editor.session.setMode "ace/mode/yaml"
        @editor.setShowPrintMargin no
        @editor.setFontSize 17
        @editor.setValue @yardCfg, -1 if @yardCfg
        @editor.on 'change', () => @saved = no

        @dismissError = null

    beforeRouteLeave: (to, from, next) ->
        unless @saved
            @$q.dialog {
                title: 'Not saved!'
                ok: {label: 'Leave, I don\'t care!', color: 'negative'}
                cancel: 'Oops, Stay here!'
            }
            .onOk =>
                # @dismissError() if @dismissError
                next()
            .onCancel -> next false
        else
            # @dismissError() if @dismissError
            next()

    methods:
        save: ->
            # if @dismissError           #! do not work!!!
            #     @dismissError()
            #     @dismissError = null

            @$http.post 'yards/configuration', @editor.getValue(), {headers:{"Content-Type":"text/yaml"}}
            .then =>
                @saved = yes
                @$q.notify {type:'positive', position:'top-right', message: 'Done'}
            .catch (err) =>
                @dismissError = @$q.notify {
                    type:'negative'
                    position:'top-right'
                    message: err.response.data
                    timeout:0
                    actions: [ label: 'Close' ]
                }

</script>

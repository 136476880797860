<template lang="pug">
q-page#dashboardpage.p2

    //- div {{$store.state.auth}}

    .row.q-col-gutter-sm
        .col-3(v-for="yard in yards" :key="yard.group+yard.ref")
            q-card.mb2.shadow-6
                img(:src="'thumb/'+yard.id+'.gif'")
                q-card-section
                    .text-h6 {{yard.group}}/{{yard.name}}
                    .x-info
                        div
                            | Last config:
                            span
                                | {{niceDate(yard.lastCfgDate, true)}}
                                q-tooltip {{niceDate(yard.lastCfgDate)}}
                        div
                            | Last Down Sync:
                            span
                                | {{niceDate(yard.lastSync, true)}}
                                q-tooltip {{niceDate(yard.lastSync)}}
                q-separator
                //- .mh2
                q-card-section
                    div(v-if="auth.user=='ars'")
                        .row.q-col-gutter-xs.justify-around
                            .col
                                q-btn.full-width(
                                    color="grey-7"
                                    size="xs"
                                    @click="$router.push({name:'config',params:{group:yard.group, yard:yard.ref}})"
                                    ) Cfg
                            .col
                                q-btn.full-width(
                                    v-if="yard.lastCfg"
                                    color="grey-7"
                                    size="xs"
                                    @click="$router.push({name:'graphic',params:{group:yard.group, yard:yard.ref, yid:yard.id}})"
                                    ) Map
                            .col
                                form(
                                    v-if="yard.lastCfg"
                                    method="get"
                                    :action="$cfg.apiURL+`yards/configuration/${yard.group}/${yard.ref}`"
                                    )
                                    input(type="hidden" name="export" value="1")
                                    input(type="hidden" name="json" value="1")
                                    //- input(type="hidden" name="yaml" value="1")
                                    q-btn.full-width(type="submit" color="grey-7" size="xs") Down
                            // q-btn.full-width(
                                color="blue-7"
                                size="xs"
                                @click=""
                                disable
                                ) Live
                            .col(v-if="yard.sync=='true'")
                                q-btn.full-width(
                                    v-if="yard.lastCfg && yard.lastCfgDate != yard.lastSyncDate"
                                    color="red-7"
                                    size="xs"
                                    @click=""
                                    disable
                                    ) UpSync
                    .mt1(v-if="yard.events=='true'")
                        .row.q-col-gutter-xs.justify-around
                            .col
                                q-btn.full-width(
                                    color="grey-7"
                                    size="sm"
                                    @click="$router.push({name:'events',params:{group:yard.group, yard:yard.ref, yid:yard.id}})"
                                    ) {{$t('dash.events')}}
                    .mt1(v-if="yard.users=='true'")
                        .row.q-col-gutter-xs.justify-around
                            .col
                                q-btn.full-width(
                                    color="grey-7"
                                    size="sm"
                                    disable
                                    @click="$router.push({name:'??????',params:{group:yard.group, yard:yard.ref}})"
                                    ) {{$t('dash.operators')}}
                    .mt1(v-if="yard.cutlist=='true'")
                        .row.q-col-gutter-xs.justify-around
                            .col
                                q-btn.full-width(
                                    color="grey-7"
                                    size="sm"
                                    disable
                                    @click="$router.push({name:'????',params:{group:yard.group, yard:yard.ref}})"
                                    ) {{$t('dash.cutlists')}}


                //- q-card-actions vaca



    //- q-card.mb2.shadow-6(v-for="yard in yards" :key="yard.group+yard.ref")
        q-card-title
            .x-title.row.items-center
                .col-auto {{yard.group}}/{{yard.name}}
                .col.x-info
                    div
                        | Last config:
                        span
                            | {{niceDate(yard.lastCfgDate, true)}}
                            q-tooltip {{niceDate(yard.lastCfgDate)}}
                    div
                        | Last Sync:
                        span
                            | {{niceDate(yard.lastSync, true)}}
                            q-tooltip {{niceDate(yard.lastSync)}}
            .x-buttons(slot="right")
                template(v-if="group=='ars'")
                    q-btn(
                        color="grey-7"
                        size="sm"
                        @click="$router.push({name:'config',params:{group:yard.group, yard:yard.ref}})"
                        ) Config
                    q-btn(
                        v-if="yard.lastCfg"
                        color="grey-7"
                        size="sm"
                        @click="$router.push({name:'graphic',params:{group:yard.group, yard:yard.ref}})"
                        ) Graphics
                    form(
                        v-if="yard.lastCfg"
                        method="get"
                        :action="$cfg.apiURL+`yards/configuration/${yard.group}/${yard.ref}`"
                        )
                        input(type="hidden" name="export" value="1")
                        q-btn(type="submit" color="grey-7" size="sm") Download
                    // q-btn(
                        color="blue-7"
                        size="sm"
                        @click=""
                        disable
                        ) Live
                    q-btn(
                        v-if="yard.lastCfg && yard.lastCfgDate != yard.lastSyncDate"
                        color="red-7"
                        size="sm"
                        @click=""
                        disable
                        ) Sync
                q-btn(
                    v-if="yard.lastCfg"
                    color="grey-7"
                    size="sm"
                    @click="$router.push({name:'events',params:{group:yard.group, yard:yard.ref}})"
                    ) {{$t('dash.events')}}
                q-btn(
                    color="grey-7"
                    size="sm"
                    disable
                    @click="$router.push({name:'operators',params:{group:yard.group, yard:yard.ref}})"
                    ) {{$t('dash.operators')}}
                q-btn(
                    color="grey-7"
                    size="sm"
                    @click=""
                    disable
                    ) {{$t('dash.cutlists')}}
                q-btn(
                    color="grey-7"
                    size="sm"
                    @click=""
                    disable
                    ) {{$t('dash.tagReader')}}

        q-card-separator
        q-card-main.row
            .col.x-fixH
                bar-chart(:data="yard._g1.data" :options="yard._g1.ops")
            .col.x-fixH
                bar-chart(:data="yard._g2.data" :options="yard._g2.ops")
            // .col.x-fixH
                bar-chart(:data="yard._g3.data" :options="yard._g3.ops")

</template>


<style lang="stylus">
#dashboardpage
    // .x-fixH > div
    //     height 260px !important

    .x-info
        margin-left 15px
        // margin-top -16px
        font-size .8rem
        line-height .9rem
        color grey
        span
            margin-left 8px

    // .x-buttons
    //     button
    //         margin-left 8px
    //         padding 0 8px

    //     form
    //         display inline-block

</style>


<script lang="coffee">

# {Bar} = require 'vue-chartjs'
moment = require 'moment'

module.exports =
    name: 'dashboard'

    data: ->
        yards: []

    computed:
        auth: -> @$store.state.auth

    # components:
        # barChart:
        #     extends: Bar
        #     name: 'vue-chartjs-bar'
        #     props: ['data', 'options']
        #     mounted: -> @renderChart @data, @options

    created: ->
        @$store.commit 'SET_YARD', null
        @$http.get 'yards'
        .then (r) =>
            @yards = r.data
            # for y in r.data
            #     y._g1 =
            #         ops:
            #             title: {display:yes, text: @$t('dash.g1title')}
            #             responsive: yes
            #             maintainAspectRatio: no
            #             scales:
            #                 xAxes: [ {ticks: {autoSkip: no, stacked:yes}} ]
            #                 yAxes: [ {ticks: {beginAtZero: yes, stacked:yes}} ]
            #         data:
            #             labels: [],
            #             datasets: [
            #                 {label: @$t('dash.g1data1'), data: [], backgroundColor: '#f44c', stack:'1'}
            #                 {label: @$t('dash.g1data2'), data: [], backgroundColor: '#32f8', stack:'1'}
            #                 # {label: @$t('dash.g1data1'), data: [], borderColor: '#f00', backgroundColor: '#f003'}
            #                 # {label: @$t('dash.g1data2'), data: [], fill:no, spanGaps:yes, borderColor: '#00f'}
            #             ]

            #     tmp = []
            #     tmp.push {sw:k, moves:v, cmds:y._metrics.commands[k]} for k, v of y._metrics.moves
            #     tmp = tmp.sort (a, b) -> b.moves - a.moves
            #     for i in tmp
            #         y._g1.data.labels.push i.sw
            #         y._g1.data.datasets[0].data.push i.moves-i.cmds
            #         y._g1.data.datasets[1].data.push i.cmds

            #     y._g2 =
            #         ops:
            #             title: {display:yes, text: @$t('dash.g2title')}
            #             maintainAspectRatio: no
            #             scales:
            #                 xAxes: [ {ticks: {autoSkip: no}} ]
            #                 yAxes: [ {ticks: {beginAtZero: yes}} ]
            #         data:
            #             labels: [],
            #             datasets: [
            #                 # {label: @$t('dash.g2data1'), data: [], borderColor: '#f84', backgroundColor: '#f843'}
            #                 {label: @$t('dash.g2data1'), data: [], backgroundColor: '#f848'}
            #             ]

            #     tmp = []
            #     tmp.push {sw:k, rotes:v} for k, v of y._metrics.destinations
            #     tmp = tmp.sort (a, b) -> b.rotes - a.rotes
            #     # tmp = tmp.sort (a, b) -> a.sw.localeCompare b.sw
            #     for i in tmp
            #         y._g2.data.labels.push i.sw
            #         y._g2.data.datasets[0].data.push i.rotes

            #     y._g3 =
            #         ops:
            #             title: {display:yes, text: 'What else?'}
            #             maintainAspectRatio: no
            #             scales:
            #                 xAxes: [ {ticks: {autoSkip: no}} ]
            #                 yAxes: [ {ticks: {beginAtZero: yes}} ]
            #         data:
            #             labels: [],
            #             datasets: [
            #                 # {label: 'destinations', data: [], borderColor: '#f84', backgroundColor: '#f843'}
            #             ]

        .catch (err) ->
            console.error err

    methods:
        download: (yard) ->
            @$http.get "yards/configuration/#{yard.group}/#{yard.name}", {params:{export: '1'}}
            .then (res) =>
                # @yardCfg = res.data
                # @editor.setValue @yardCfg, -1 if @editor
            .catch (err) =>
                console.error err

        niceDate: (d, relative) ->
            return '---' unless d
            return moment().from d, true if relative
            moment(d).format 'llll'

 </script>

<template lang="pug">
.yard-map()

    #yard-area

</template>

<style lang="stylus">
.yard-map
    #yard-area
        user-select none

        // background-color: #2d3033
//         position absolute
//         top 0
//         bottom 0
//         left 0
//         right 0
</style>

<script lang="coffee">

merge = require 'lodash.merge'

{SVG} = require '@svgdotjs/svg.js'
require '@svgdotjs/svg.draggable.js'

Terminal = require './terminal'
Switch = require './switch'
Rail = require './rail'
Asset = require './asset'
# Derail = require './derail'
Light = require './light'
Label = require './label'
# Manual = require './manual'
Zone = require './zone'
Note = require './note'
ControlClassBtn = require './controlClassBtn'
ControlClassList = require './controlClassList'
ControlRouteList = require './controlRouteList'
Editor = require './editor'


module.exports =
    name: 'yard-map'

    # props: ['yardCfg', 'height', 'styleOverload', 'dev', 'defView']
    props: {
        yardCfg: {type:Object, required:yes}
        height: {type:String, default:'100%'}
        styleOverload: {type:Object, default: -> {}}
        dev: Boolean
        clone: Boolean
        defView: {type:String, default:'full'}
    }

    data: ->
        draw: null
        styles: null
        notes: {}
        views: {}
        yard: if @clone then @yardCfg else JSON.parse JSON.stringify @yardCfg
        activeView: null
        backArea: null

    mounted: ->
        @draw = SVG().id('svgYardArea').addTo('#yard-area').size('100%', @height)

        @styles = merge {}, @yard.gui.styles, @styleOverload

        @paint()

        scan = (obj) =>
            for k, v of obj
                # console.log k, v
                if typeof v == 'object' and !Array.isArray(v)
                    scan(v)
                else
                    continue unless typeof v is 'string'

                    if v.startsWith 'stripe/'
                    # stripe/25/12/45/#4f8/#d66/#000
                    # stripe / width1 / width2 / angle / color1 / color2 / colorStroke
                        t = v.split '/'
                        w = +t[1] + +t[2]
                        # console.log '****************************',k,t, w
                        obj[k] = {
                            stroke: t[6]
                            fill: @draw.pattern w, w, (add) =>
                                    add.rect(+t[1], w).fill(t[4])
                                    add.rect(+t[2], w).fill(t[5])
                                .rotate(+t[3])
                        }
            return

        scan @styles

        # @styles.switch.ooc_mow ?=
        #     @draw.pattern 16, 16, (add) =>
        #         add.rect(8, 16).fill 'orange'
        #         add.rect(8, 16).move(8, 0).fill '#28f'

        # @styles.terminal.target_issue ?= {
        #     stroke: '#000'
        #     fill: @draw.pattern 25, 25, (add) =>
        #             add.rect(25, 25).fill('#4f8')
        #             add.rect(12, 25).fill('#d66')
        #           .rotate(45)
        # }

        @$emit 'done'

    methods:
        getSVG: ->
            return {draw:@draw, SVG, rect:@backArea}

        ###
        update the graphical state of a gui item
        in the case of a crossover the state is shared by both switches
        ###
        update: (name, state) ->
            # console.log ">>>>>>>", name, state
            for view, {items} of @views
                if name of items

                    items[name].setState state
                    if items[name].ops.xover
                        items['_'+name].setState state

            return

        getStatus: (name) ->
            o = @views.full.items[name]
            return null unless o
            tmp = Object.assign {}, o.state
            tmp._ops = o.ops
            return tmp

        highlight: (name, type) -> @views.full.items[name]?.highlight type

        addNote: (id, txt) ->
            @notes[id] = txt
            n = new Note id, @draw, @styles, txt

        setView: (view) ->
            for k, v of @views
                # v.render.opacity if view == k then 1 else 0
                # v.render.style.display = if view == k then 'block' else 'node'
                document.getElementById("view-#{k}").style.display = if view == k then 'block' else 'none'
            @activeView = view
            return

        paint: ->
            @backArea = @draw
            .clear()
            .rect(@yard.gui.size[0], (@yard.gui.size[1] - @yard.gui.styles.topBarHeight))
            .attr({fill: @styles.background})

            for view in @yard.gui.views
                v = @draw.group({id:"view-#{view}"})
                viewObj = @yard.gui[view]
                # console.log viewObj
                @views[view] = {
                    label: viewObj.label
                    items: {}
                    render: v
                }

                layers = [
                    v.group()  #  ???
                    v.group()  #  rails
                    v.group()  #  terminals, click circles and some assets
                    v.group()  #  labels
                ]

                for k, t of viewObj.terminals
                    term = new Terminal k, layers, @styles, t
                    term.on 'click', (name, obj) => @$emit 'click', 'terminal', name, obj
                    @views[view].items[k] = term

                for k, s of viewObj.switches
                    s.dev = @dev
                    s.xover = viewObj.switches['_' + k]?
                    if k[0] == '_'
                        s.remote = yes
                        # s.label = viewObj.switches[k[1...]].label
                        s.manual = !@yard.remotes[k[1...]]?
                    else
                        s.manual = !@yard.remotes[k]?
                    s.derail = @yard.remotes[k]?.type == 'derail'
                    sw = new Switch k, layers, @styles, s
                    if not s.manual or @dev
                        sw.on 'click', (type, name, obj) => @$emit 'click', type, name, obj
                    @views[view].items[k] = sw

                for k, l of viewObj.lights
                    rel = new Light k, layers, @styles, l
                    rel.on 'click', (type, name, obj) => @$emit 'click', type, name, obj
                    @views[view].items[k] = rel

                for k, r of viewObj.zones
                    rel = new Zone k, layers, @styles, r
                    rel.on 'click', (type, name, obj) => @$emit 'click', type, name, obj
                    @views[view].items[k] = rel

                for k, r of viewObj.rails
                    r.dev = @dev
                    for {cl, cr, name, label} in @yard.connections
                        if name == k
                            r.label = label
                            rail = new Rail name, layers, @views[view].items, cl, cr, @styles, r
                            if @dev
                                rail.on 'click', (name, obj) => @$emit 'click', 'rail', name, obj
                            break

                for k, c of viewObj.controls
                    c.dev = @dev
                    switch c.type
                        when 'classBtn'
                            con = new ControlClassBtn k, layers, @styles, c
                            con.on 'click', (name, obj) => @$emit 'click', 'control', name, obj
                        when 'classList'
                            con = new ControlClassList k, layers, @styles, c
                            con.on 'click', (name, obj) => @$emit 'click', 'control', name, obj
                        when 'plugin'
                            con = null
                            # TODO
                        when 'routeList'
                            con = new ControlRouteList k, layers, @styles, c
                            con.on 'click', (name, obj) => @$emit 'click', 'control', name, obj

                    @views[view].items[k] = con

                for e in viewObj.editors
                    c.dev = @dev
                    ed = new Editor e.id, layers, @styles, e
                    ed.on 'click', (name, obj) => @$emit 'click', 'editor', name, obj
                    @views[view].items[k] = ed

                for a, idx in viewObj.assets
                    a.dev = @dev
                    aid = "_ass-#{idx}"
                    ass = new Asset aid, layers, a
                    if @dev
                        ass.on 'click', (name, obj) => @$emit 'click', 'asset', name, obj
                    @views[view].items[aid] = ass

                for l, idx in viewObj.labels
                    l.dev = @dev
                    lid = "_lab-#{idx}"
                    lab = new Label lid, layers, @styles, l
                    if @dev
                        lab.on 'click', (name, obj) => @$emit 'click', 'label', name, obj
                    @views[view].items[lid] = lab

                # for id, txt of @notes
                #     new Note id, @draw, @styles, txt

                if view == @defView
                    @activeView = view
                    document.getElementById("view-#{view}").style.display = 'block'
                else
                    document.getElementById("view-#{view}").style.display = 'none'

            @draw.viewbox 0, 0, @yard.gui.size[0], (@yard.gui.size[1] - @yard.gui.styles.topBarHeight)

            # @draw.animate({duration:500}).viewbox 900, 250, @yard.gui.size[0]/2, @yard.gui.size[1]/2
            # .animate({duration:500, delay:5000}).viewbox 0, 0, @yard.gui.size[0], @yard.gui.size[1]
</script>
